html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.FormModal {
  background-color: #fff;
  width: 100%;
  max-width: 900px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  overflow: auto;
}

.FormModal:focus {
  outline: none;
}

.FormModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(44, 44, 44, 0.65);
  overflow: auto;
  z-index: 1200; /* on top of most things */
}

body.ReactModal__Body--open {
  overflow: hidden;
  height: 100vh;
}

.Toastify__toast--info {
  background-color: #2196f3;
}

.Toastify__toast--success {
  background-color: #4caf50;
}

.Toastify__toast--warning {
  background-color: #ff9800;
}

.Toastify__toast--error {
  background-color: #f44336;
}

/* material ui overrides don't seem to be working so I am putting these here for now */
.MuiInputBase-root {
  background-color: #ffffff;
}

[class*='MuiAccordionSummary-root'].Mui-expanded {
  min-height: auto;
}

[class*='MuiAccordionSummary-content'].Mui-expanded {
  margin: 0.85rem 0;
}
